import { TextLength } from 'types'

const SHORT = 'M:SS'
const DISPLAY = 'M:SS.XX'
const VERBOSE = 'H:MM:SS.XX'

const getString = (length: TextLength): string => {
    switch (length) {
        case 'short':
            return SHORT
        case 'display':
            return DISPLAY
        case 'verbose':
            return VERBOSE
    }
}

/**
 * Returns display text for a time in seconds
 */
export function timeToText(seconds: number, length: TextLength): string {
    const ms = Math.floor((Math.floor((seconds * 1000) % 1000) / 1000) * 100)
    const s = Math.floor(seconds % 60)
    const m = Math.floor(((seconds * 1000) / (1000 * 60)) % 60)
    const h = Math.floor(seconds / 60 / 60)
    const text = getString(length)

    return text
        .replace('H', `${h}`)
        .replace('MM', `${m}`.padStart(2, '0'))
        .replace('M', `${m + h * 60}`)
        .replace('SS', `${s}`.padStart(2, '0'))
        .replace('XX', `${ms}`.padStart(2, '0'))
}

/**
 * Returns a value in seconds from time display text
 */
export function timeFromText(text: string): number {
    const segments = text.split(':')
    let h = 0
    let m = 0
    let s = 0
    let time = 0

    if (segments.length > 2) {
        h = parseInt(segments[0])
        m = parseInt(segments[1])
        s = parseFloat(segments[2])
    } else {
        m = parseInt(segments[0])
        s = parseFloat(segments[1])
    }

    time += isNaN(h) ? 0 : h * Math.pow(60, 2)
    time += isNaN(m) ? 0 : m * 60
    time += isNaN(s) ? 0 : s

    return time
}
