import { colorToRgb, colorToRgbArray } from './rgb'

function rgbToHsv(rgb: string): string {
    const rgbArray = colorToRgbArray(rgb)

    if (rgbArray.length === 3) {
        const r = rgbArray[0] / 255
        const g = rgbArray[1] / 255
        const b = rgbArray[2] / 255
        const v = Math.max(r, g, b)
        const diff = v - Math.min(r, g, b)
        const diffc = function (c: number) {
            return (v - c) / 6 / diff + 1 / 2
        }

        let rDiff = 0
        let gDiff = 0
        let bDiff = 0
        let h = 0
        let s = 0

        if (diff === 0) {
            h = 0
            s = 0
        } else {
            s = diff / v
            rDiff = diffc(r)
            gDiff = diffc(g)
            bDiff = diffc(b)

            if (r === v) {
                h = bDiff - gDiff
            } else if (g === v) {
                h = 1 / 3 + rDiff - bDiff
            } else if (b === v) {
                h = 2 / 3 + gDiff - rDiff
            }

            if (h < 0) {
                h += 1
            } else if (h > 1) {
                h -= 1
            }
        }

        return `hsv(${Math.round(h * 360)}, ${Math.round(s * 1000) / 1000}, ${Math.round(v * 1000) / 1000})`
    }

    return ''
}

export function colorToHsvArray(color: string): number[] {
    let hsvString = ''

    if (color.trim().indexOf('rgb') === 0) {
        hsvString = rgbToHsv(color)
    } else if (color.trim().indexOf('hsv') === 0) {
        hsvString = color.trim()
    } else {
        hsvString = rgbToHsv(colorToRgb(color))
    }

    if (hsvString.length > 0) {
        const segments = hsvString.split('(')[1].split(')')[0].split(',')
        const values = segments.map((value) => parseFloat(value.trim()))

        if (values && values.length >= 3) {
            return [values[0], values[1], values[2]]
        }
    }

    return []
}

export function colorToHsv(color: string): string {
    const hsv = colorToHsvArray(color)

    if (hsv.length === 3) {
        return `hsv(${hsv.join(', ')})`
    }

    return ''
}
