import { colorToRgb } from './rgb'

function rgbToHsl(rgb: string): string {
    const rgbValue = colorToRgb(rgb)
    const values = rgbValue.match(/[\d\.]+/g)?.map((value) => parseInt(value))

    if (values && values.length >= 3) {
        const r = values[0] / 255
        const g = values[1] / 255
        const b = values[2] / 255
        const min = Math.min(r, g, b)
        const max = Math.max(r, g, b)
        const delta = max - min
        let h = 0
        let s = 1

        if (max === min) {
            h = 0
        } else if (r === max) {
            h = (g - b) / delta
        } else if (g === max) {
            h = 2 + (b - r) / delta
        } else if (b === max) {
            h = 4 + (r - g) / delta
        }

        h = Math.min(h * 60, 360)

        if (h < 0) {
            h += 360
        }

        const l = (min + max) / 2

        if (max === min) {
            s = 0
        } else if (l <= 0.5) {
            s = delta / (max + min)
        } else {
            s = delta / (2 - max - min)
        }

        return `hsl(${Math.round(h)}, ${Math.round(s * 1000) / 1000}, ${Math.round(l * 1000) / 1000})`
    }

    return ''
}

export function colorToHslArray(color: string): number[] {
    let hslString = ''

    if (color.trim().indexOf('rgb') === 0) {
        hslString = rgbToHsl(color)
    } else if (color.trim().indexOf('hsl') === 0) {
        hslString = color.trim()
    } else {
        hslString = rgbToHsl(colorToRgb(color))
    }

    if (hslString.length > 0) {
        const segments = hslString.split('(')[1].split(')')[0].split(',')
        const values = segments.map((value) => {
            if (value.indexOf('%') > -1) {
                return parseInt(value) / 100
            }
            return parseFloat(value.trim())
        })

        if (values && values.length >= 3) {
            return [values[0], values[1], values[2]]
        }
    }

    return []
}

export function colorToHsl(color: string): string {
    const hsl = colorToHslArray(color)

    if (hsl.length === 3) {
        return `hsl(${hsl.join(', ')})`
    }

    return ''
}
