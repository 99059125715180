import { colorToHex } from './hex'
import { colorToHslArray } from './hsl'
import { colorToHsvArray } from './hsv'

function hexToRgb(hex: string): string {
    const hexValue = colorToHex(hex)
    const values = hexValue.substring(1).match(/.{1,2}/g)

    if (values && values.length >= 3) {
        const r = parseInt(values[0], 16)
        const g = parseInt(values[1], 16)
        const b = parseInt(values[2], 16)

        return `rgb(${r}, ${g}, ${b})`
    }

    return ''
}

function hslToRgb(hsl: string): string {
    const hslArray = colorToHslArray(hsl)

    if (hslArray.length === 3) {
        const h = hslArray[0] / 360
        const s = hslArray[1]
        const l = hslArray[2]
        let t2 = 0
        let t3 = 0
        let val = 0

        if (s === 0) {
            val = l * 255
            return `rgb(${val}, ${val}, ${val})`
        }

        if (l < 0.5) {
            t2 = l * (1 + s)
        } else {
            t2 = l + s - l * s
        }

        const t1 = 2 * l - t2
        const rgb = [0, 0, 0]

        for (let i = 0; i < 3; i++) {
            t3 = h + (1 / 3) * -(i - 1)

            if (t3 < 0) {
                t3++
            }

            if (t3 > 1) {
                t3--
            }

            if (6 * t3 < 1) {
                val = t1 + (t2 - t1) * 6 * t3
            } else if (2 * t3 < 1) {
                val = t2
            } else if (3 * t3 < 2) {
                val = t1 + (t2 - t1) * (2 / 3 - t3) * 6
            } else {
                val = t1
            }

            rgb[i] = Math.round(val * 255)
        }

        return `rgb(${rgb.join(', ')})`
    }

    return ''
}

function hsvToRgb(hsv: string): string {
    const hsvArray = colorToHsvArray(hsv)

    if (hsvArray.length === 3) {
        const h = hsvArray[0] / 60
        const s = hsvArray[1]
        let v = hsvArray[2]
        const hi = Math.floor(h) % 6
        const f = h - Math.floor(h)
        const p = Math.round(255 * v * (1 - s))
        const q = Math.round(255 * v * (1 - s * f))
        const t = Math.round(255 * v * (1 - s * (1 - f)))
        let rgb = [0, 0, 0]

        v = Math.round(v * 255)

        switch (hi) {
            case 0:
                rgb = [v, t, p]
                break
            case 1:
                rgb = [q, v, p]
                break
            case 2:
                rgb = [p, v, t]
                break
            case 3:
                rgb = [p, q, v]
                break
            case 4:
                rgb = [t, p, v]
                break
            case 5:
                rgb = [v, p, q]
                break
        }

        return `rgb(${rgb.join(', ')})`
    }

    return ''
}

export function colorToRgbArray(color: string): number[] {
    let rgbString = ''

    if (color.trim().indexOf('#') === 0) {
        rgbString = hexToRgb(color)
    } else if (color.trim().indexOf('hsl') === 0) {
        rgbString = hslToRgb(color)
    } else if (color.trim().indexOf('hsv') === 0) {
        rgbString = hsvToRgb(color)
    } else if (color.trim().indexOf('rgb') === 0) {
        rgbString = color.trim()
    }

    if (rgbString.length > 0) {
        const segments = rgbString.split('(')[1].split(')')[0].split(',')
        const values = segments.map((value) => parseInt(value.trim()))

        if (values && values.length >= 3) {
            return [values[0], values[1], values[2]]
        }
    }

    return []
}

export function colorToRgb(color: string): string {
    const rgb = colorToRgbArray(color)

    if (rgb.length === 3) {
        return `rgb(${rgb.join(', ')})`
    }

    return ''
}
