import { RefObject, useEffect, useState } from 'react'
import { Dimensions } from 'types'

const useRefDimensions = (ref: RefObject<HTMLElement | null>) => {
    const [dimensions, setDimensions] = useState<Dimensions | null>(null)

    useEffect(() => {
        if (!ref.current) return

        const observer = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const { borderBoxSize, contentRect } = entry

                if (borderBoxSize?.length) {
                    const { blockSize, inlineSize } = borderBoxSize[0]
                    setDimensions({
                        height: blockSize,
                        width: inlineSize,
                    })
                } else {
                    setDimensions({
                        height: contentRect.height,
                        width: contentRect.width,
                    })
                }
            })
        })

        observer.observe(ref.current)

        return () => {
            observer.disconnect()
        }
    }, [ref])

    return dimensions
}

export default useRefDimensions
