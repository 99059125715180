const CANVAS = typeof window !== 'undefined' ? document?.createElement('canvas') : null
const CONTEXT = CANVAS?.getContext('2d')

export async function measureTextWidth(
    text: string,
    fontSize: number,
    fontFamily: string,
    fontWeight: string = 'bold'
): Promise<number> {
    if (!text || !CANVAS || !CONTEXT) {
        return 0
    }

    const font = `${fontWeight} ${fontSize}px ${fontFamily}`

    // preload font
    if ((document as any)?.fonts) {
        try {
            await (document as any).fonts.load(font)
        } catch (err) {}
    }

    CONTEXT.font = font

    return CONTEXT.measureText(text).width
}
