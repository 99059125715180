export { fileExtensionFromUrl, vimeoIdFromUrl, youtubeIdFromUrl } from './file-extension'

export const GIF_ID_ALLOWED_CHARS_REGEX = '[A-Za-z0-9]'
export const GIF_ID_REGEX = GIF_ID_ALLOWED_CHARS_REGEX + '{6,24}'
export const GIF_SLUG_ALLOWED_CHARS_REGEX = '[A-Za-z0-9-]'
export const GIF_SLUG_REGEX = GIF_SLUG_ALLOWED_CHARS_REGEX + '+'

export const getShareUrl = (url: string) => url.replace(/media\d+\.giphy\.com/, 'media.giphy.com')
export const getSearchPath = (term = '', isSticker = false) =>
    `/search/${termValidation(term)}${isSticker ? '-stickers' : ''}`

export const getExplorePath = (term = '', isSticker = false) => {
    if (isSticker) {
        return getSearchPath(term, isSticker)
    }
    return `/explore/${termValidation(term)}`
}

export const termValidation = (term: string) =>
    `${term
        .replace(/[^\w\s-@]+/g, '')
        .replace(/\s\s+/g, ' ')
        .trim()
        .replace(/ /g, '-')}`

export const getGifIdFromUrl = (url: string): string | null => {
    const regex = new RegExp(`\/(gifs|stickers|clips|media)\/(${GIF_SLUG_REGEX}\-)?(${GIF_ID_REGEX})\/?`)
    const match = url.match(regex)
    if (match) {
        const extractedId = match[3]
        return extractedId
    } else {
        return null
    }
}
export const isGifIdValid = (gifId: string): boolean => {
    const regex = new RegExp(`^${GIF_ID_REGEX}$`)
    const match = gifId.match(regex)
    return !!match
}
