import { colorToRgbArray } from './rgb'

export function colorToRgbaArray(color: string, opacity: number): number[] {
    const rgb = colorToRgbArray(color)

    if (rgb.length === 3) {
        return [...rgb, opacity]
    }

    return []
}

/**
 * Returns an rgba string from a hex or rgb color string and opacity (0-1)
 */
export function colorToRgba(color: string, opacity: number): string {
    const rgba = colorToRgbaArray(color, opacity)

    if (rgba.length === 4) {
        return `rgba(${rgba.join(', ')})`
    }

    return ''
}
