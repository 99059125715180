import { RefObject, useEffect, useState } from 'react'

const useNodeWidth = (ref: RefObject<HTMLElement>, disabled: boolean = false): number => {
    const [width, setWidth] = useState<number>(0)

    useEffect(() => {
        if (!ref.current || disabled) return

        const callback = () => {
            if (!ref.current) return

            setWidth(ref.current.clientWidth)
        }

        const resize = new ResizeObserver(callback)
        resize.observe(ref.current)
        callback()

        return () => {
            resize.disconnect()
        }
    }, [disabled, ref.current])

    return width
}

export default useNodeWidth
