import { useCallback, useRef } from 'react'
import useUpdate from 'react-use/lib/useUpdate'

const useNodeRef = () => {
    const node = useRef<HTMLElement | null>(null)
    const update = useUpdate()

    const setNodeRef = useCallback(
        (element: HTMLElement | null) => {
            node.current = element
            update()
        },
        [update]
    )

    return [node, setNodeRef] as const
}

export default useNodeRef
