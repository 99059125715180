import { MediaType, mediaTypes } from 'types'
import { fileExtensionFromUrl } from '../url'
import { fileExtensionsFromMedia, mimeTypesFromMedia } from './media-type'

export function identifyMedia(source: File | string): MediaType | undefined {
    if (typeof source === 'string') {
        const fileExtension = fileExtensionFromUrl(source)
        return mediaTypes.find((media) => fileExtensionsFromMedia(media).indexOf(fileExtension) > -1)
    }

    return mediaTypes.find((media) => mimeTypesFromMedia(media).indexOf(source.type) > -1)
}
