import { TextLength } from 'types'
import { pluralize } from '../text/string'

type DurationItem = {
    short: string
    long: string
    value: number
}

const getText = (item: DurationItem, length: TextLength, overflow?: DurationItem): string => {
    const label = pluralize(item.long, item.value)

    if (overflow?.value) {
        const overflowLabel = pluralize(overflow.long, overflow.value)

        switch (length) {
            case 'short':
                return `${Math.round((item.value + overflow.value / 60) * 10) / 10}${item.short}`
            case 'display':
                return `${item.value}${item.short} ${overflow.value}${overflow.short}`
            case 'verbose':
                return `${item.value} ${label} and ${overflow.value} ${overflowLabel}`
        }
    }

    switch (length) {
        case 'short':
        case 'display':
            return `${item.value}${item.short}`
        case 'verbose':
            return `${item.value} ${label}`
    }
}

export function durationToText(seconds: number, length: TextLength = 'display'): string {
    const h = { short: 'h', long: 'hour', value: Math.floor(seconds / 3600) }
    const m = { short: 'm', long: 'minute', value: Math.floor((seconds % 3600) / 60) }
    const s = { short: 's', long: 'second', value: Math.floor((seconds % 3600) % 60) }

    // hours
    if (h.value > 0) {
        return getText(h, length, m)
    }

    // minutes
    if (m.value > 0) {
        return getText(m, length, s)
    }

    // seconds
    return getText(s, length)
}

export function durationFromText(text: string): number {
    const isVerbose = text.indexOf('hour') > -1 || text.indexOf('minute') > -1 || text.indexOf('second') > -1
    const s = isVerbose ? 'second' : 's'
    const m = isVerbose ? 'minute' : 'm'
    const h = isVerbose ? 'hour' : 'h'
    const divider = isVerbose ? 'and' : ' '
    let duration = 0

    text.split(divider).forEach((segment) => {
        const value = parseFloat(segment)

        if (isNaN(value)) {
            duration += 0
        } else if (segment.indexOf(s) > -1) {
            duration += value
        } else if (segment.indexOf(m) > -1) {
            duration += value * 60
        } else if (segment.indexOf(h) > -1) {
            duration += value * 60 * 60
        }
    })

    return duration
}
