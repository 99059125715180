import { ControlSize } from 'types'

/**
 * Returns the control size's border radius.
 */
export function borderRadiusFromControlSize(size: ControlSize, full: boolean = false): number {
    if (full) {
        return Math.round(heightFromControlSize(size) / 2)
    }

    switch (size) {
        case 'large':
        case 'x-large':
            return 6
        case 'mini':
            return 2
        default:
            return 4
    }
}

/**
 * Returns the control size's font size in pixels.
 */
export function fontSizeFromControlSize(size: ControlSize): number {
    switch (size) {
        case 'mini':
            return 12
        case 'small':
        case 'medium':
            return 15
        case 'large':
        case 'x-large':
            return 17
    }
}

export function fontShadowFromControlSize(size: ControlSize, opacity: number = 0.5): string {
    const fontSize = fontSizeFromControlSize(size)
    const y = Math.round(fontSize / 25)
    const blur = Math.round(fontSize / 10)

    return `0 ${y}px ${blur}px rgba(0, 0, 0, ${opacity})`
}

/**
 * Returns the control size's height in pixels.
 */
export function heightFromControlSize(size: ControlSize): number {
    switch (size) {
        case 'mini':
            return 20
        case 'small':
            return 34
        case 'medium':
            return 40
        case 'large':
            return 50
        case 'x-large':
            return 60
    }
}
