/**
 * Returns brightness value (0-1) from an array of rgb values
 */
export function brightnessFromRgbArray(rgb: number[]): number {
    if (rgb.length < 3) {
        return 0
    }

    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000

    return brightness / 255
}

/**
 * Returns brightness value (0-1) from a hex, rgb, or rgba string
 */
export function brightnessFromColor(color: string): number {
    const isHex = color.indexOf('#') === 0
    const isRgb = color.indexOf('rgb') === 0

    if (isHex) {
        const isFull = color.length === 7
        const values = color.substring(1).match(isFull ? /(\S{2})/g : /(\S{1})/g)

        if (values) {
            const r = parseInt(values[0] + (isFull ? '' : values[0]), 16)
            const g = parseInt(values[1] + (isFull ? '' : values[1]), 16)
            const b = parseInt(values[2] + (isFull ? '' : values[2]), 16)

            if (!isNaN(r) && !isNaN(g) && !isNaN(b)) {
                return brightnessFromRgbArray([r, g, b])
            }
        }
    }

    if (isRgb) {
        const values = color.match(/[\d\.]+/g)?.map((value) => parseInt(value))

        if (values && values.length >= 3) {
            return brightnessFromRgbArray([values[0], values[1], values[2]])
        }
    }

    return 0
}
