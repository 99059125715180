/**
 * Returns a string pluralized appropriately based on a number value
 */
export function pluralizeNumber(value: number, singularLabel: string): string {
    if (value === 1) {
        return singularLabel
    }

    return `${singularLabel}s`
}
