import { useEffect, useState } from 'react'

function useDebounceValue<T>(value: T, delay: number = 300): T {
    const [state, setState] = useState<T>(value)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setState(value)
        }, delay)

        return () => {
            clearTimeout(timeout)
        }
    }, [delay, value])

    return state
}

export default useDebounceValue
