import { TextFormat } from 'types'
import { colorToHex } from '../color'

export function formatText(text: string, format: TextFormat): string {
    switch (format) {
        case 'color':
            return colorToHex(text)
        default:
            return text
    }
}
