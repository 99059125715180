import { TextLength } from 'types'
import { pluralizeNumber } from './pluralize'

const SHORT = ['kb', 'mb', 'gb']
const VERBOSE = [' kilobyte', ' megabyte', ' gigabyte']

export function fileSizeToText(bytes: number, length: TextLength = 'display'): string {
    const isVerbose = length === 'verbose'
    const measurements = isVerbose ? VERBOSE : SHORT
    let text = ''

    measurements.find((measurement, i) => {
        const value = bytes / Math.pow(1024, i + 1)
        const displayValue = i > 0 ? Math.round(value * 10) / 10 : Math.floor(value)
        const label = isVerbose ? pluralizeNumber(displayValue, measurement) : measurement

        text = `${displayValue}${label}`

        return value < 1024
    })

    return text
}

export function fileSizeFromText(text: string): number {
    const value = parseFloat(text)
    let fileSize = 0

    if (isNaN(value)) {
        return 0
    }

    VERBOSE.find((measurement, i) => {
        if (text.indexOf(measurement) > -1) {
            const multiplier = Math.pow(1024, i + 1)
            fileSize = Math.floor(value * multiplier)
            return true
        }

        return false
    })

    SHORT.find((measurement, i) => {
        if (text.indexOf(measurement) > -1) {
            const multiplier = Math.pow(1024, i + 1)
            fileSize = Math.floor(value * multiplier)
            return true
        }

        return false
    })

    return fileSize
}
