import { throttle } from 'lodash'
import { RefObject, useEffect, useState } from 'react'

const useRefRect = (ref: RefObject<HTMLElement | null>, disabled: boolean = false) => {
    const [rect, setRect] = useState<DOMRect | null>(null)

    useEffect(() => {
        if (!ref.current || disabled) return

        const callback = throttle(() => {
            if (!ref.current) return

            const boundingRect = ref.current.getBoundingClientRect()

            if (boundingRect.height > 0 && boundingRect.width > 0) {
                setRect(ref.current.getBoundingClientRect())
            }
        }, 100)

        const mutation = new MutationObserver(callback)
        const resize = new ResizeObserver(callback)

        mutation.observe(ref.current, { attributes: true, childList: true, subtree: true })
        resize.observe(ref.current)
        window.addEventListener('resize', callback)
        callback()

        return () => {
            mutation.disconnect()
            resize.disconnect()
            window.removeEventListener('resize', callback)
        }
    }, [disabled, ref])

    return rect
}

export default useRefRect
