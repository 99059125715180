import {
    giphyBlue,
    giphyGreen,
    giphyIndigo,
    giphyLightBlue,
    giphyPink,
    giphyPurple,
    giphyRed,
    giphyYellow,
} from '@giphy/colors'
import { Gradient, gradients } from 'types'
import { colorToHex, colorToRgbArray } from '../color'

/**
 * Returns an array of the gradient's hex color values.
 */
export function gradientToHexColors(gradient: Gradient): string[] {
    switch (gradient) {
        case 'blue-cyan':
            return [giphyBlue, giphyLightBlue]
        case 'blue-green':
            return [giphyBlue, giphyGreen]
        case 'blue-red':
            return [giphyBlue, giphyRed]
        case 'indigo-blue':
            return [giphyIndigo, giphyBlue]
        case 'indigo-cyan':
            return [giphyIndigo, giphyLightBlue]
        case 'indigo-purple':
            return [giphyIndigo, giphyPurple]
        case 'pink-red':
            return [giphyPink, giphyRed]
        case 'purple-cyan':
            return [giphyPurple, giphyLightBlue]
        case 'purple-green':
            return [giphyPurple, giphyGreen]
        case 'purple-pink':
            return [giphyPurple, giphyPink]
        case 'purple-yellow':
            return [giphyPurple, giphyYellow]
        case 'red-pink':
            return [giphyRed, giphyPink]
        case 'red-yellow':
            return [giphyRed, giphyYellow]
        case 'purple-indigo':
            return [giphyPurple, giphyIndigo]
    }
}

/**
 * Returns the gradient's CSS linear-gradient string.
 */
export function gradientToCss(gradient: Gradient): string {
    const colors = gradientToHexColors(gradient)
    return `linear-gradient(45deg, ${colors.join(', ')})`
}

/**
 * Returns the hex color from a position [0-1] in a gradient.
 */
export function colorFromGradientPosition(gradient: Gradient, position: number): string {
    const rgbColors = gradientToHexColors(gradient).map(colorToRgbArray)
    const startIndex = Math.floor((rgbColors.length - 1) * position)
    const endIndex = Math.min(startIndex + 1, rgbColors.length - 1)
    const rgbDiff = rgbColors[endIndex].map((value, i) => value - rgbColors[startIndex][i])
    const offsetPosition = position - startIndex / (rgbColors.length - 1)
    const color = rgbDiff.map((value, i) => {
        const offset = value * offsetPosition

        return Math.round(rgbColors[startIndex][i] + offset)
    })

    return colorToHex(`rgb(${color[0]},${color[1]},${color[2]})`)
}

/**
 * Returns the gradient's CSS linear-gradient string or just the color string, if it's only a color.
 */
export function gradientToCssOrColor(gradientOrColor: any): string {
    if (gradients.includes(gradientOrColor)) {
        return gradientToCss(gradientOrColor)
    }

    return gradientOrColor
}
