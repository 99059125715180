export function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function generateId(prefix?: string): string {
    return `${prefix}${Math.random().toString(36).substring(2, 11)}`
}

export function pluralize(string: string, value: number): string {
    if (value === 1) {
        return string
    }
    return `${string}s`
}
