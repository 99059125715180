export const replaceItem = <T>(arr: T[] = [], index: number, newItem: T) => {
    const newArr = [...arr]
    newArr[index] = newItem
    return newArr
}

export const insertItem = <T>(arr: T[] = [], index: number, newItem: T) => {
    const newArr = [...arr]
    newArr.splice(index, 0, newItem)
    return newArr
}
