import { MediaType } from 'types'

export function fileExtensionsFromMedia(media: MediaType): string[] {
    switch (media) {
        case 'font':
            return ['.ttf']
        case 'gif':
            return ['.gif']
        case 'image':
            return ['.jpeg', '.jpg', '.png']
        case 'video':
            return ['.mov', '.mp4', '.webm']
    }
}

export function mimeTypesFromMedia(media: MediaType): string[] {
    switch (media) {
        case 'font':
            return ['font/ttf']
        case 'gif':
            return ['image/gif']
        case 'image':
            return ['image/jpeg', 'image/png']
        case 'video':
            return ['video/mov', 'video/mp4', 'video/quicktime', 'video/webm']
    }
}

const imageTypes = [
    'gif',
    'gifs',
    'sticker',
    'stickers',
    'video',
    'videos',
    'transparent',
    'transparents',
    'clips',
    'with-sound',
]
const search = imageTypes
    .map((type) => {
        return `\\b(${type})\\b`
    })
    .join('|')
const regExp = new RegExp(search, `gi`)

/**
 * Strip off `gif`, `gifs`, `sticker`, `stickers`, `video`, `videos`, `transparent`, `transparents`, `clips`, `with-sound` from the string
 * @param fullString
 * @returns
 */
export const removeImageTypes = (fullString = '') => {
    return fullString.replace(regExp, '').trim()
}
