import { colorToRgb, colorToRgbArray } from './rgb'

function rgbToHex(rgb: string): string {
    const rgbArray = colorToRgbArray(rgb)
    let hex = ''

    if (rgbArray.length === 3) {
        hex += '#'

        for (let i = 0; i < rgbArray.length; i++) {
            let valueHex = rgbArray[i].toString(16)

            if (valueHex.length === 1) {
                valueHex = `0${valueHex}`
            }

            hex += valueHex
        }
    }

    return hex
}

export function colorToHex(color: string): string {
    if (color.trim().indexOf('rgb') === 0) {
        return rgbToHex(color)
    }

    if (color.trim().indexOf('hsl') === 0 || color.trim().indexOf('hsv') === 0) {
        return rgbToHex(colorToRgb(color))
    }

    const segments = color.split('#')

    if (segments.length > 1) {
        let hex = segments[1].trim()

        if (hex.length === 3) {
            hex = hex
                .split('')
                .map((char) => `${char}${char}`)
                .join('')
        }

        if (hex.length === 6 && hex.match(/^[0-9a-zA-Z]+$/)) {
            return `#${hex}`
        }
    }

    return ''
}

export function normalizeHexLength(color: string): string {
    const hex = color.replace('#', '')

    if (hex.length === 3) {
        return `#${hex
            .split('')
            .map((value) => `${value}${value}`)
            .join('')}`
    }

    return `#${hex}`
}

export function csvToHexArray(colors: string): string[] {
    const values = colors
        .split(',')
        .map((color) => color.trim())
        .filter((color) => {
            const normalized = normalizeHexLength(color)

            return normalized.length === 4 || normalized.length === 7
        })

    return values
}
