import { NumberFormat, TextLength } from 'types'
import { durationFromText, durationToText } from './duration'
import { fileSizeFromText, fileSizeToText } from './file-size'
import { timeFromText, timeToText } from './time'

export function displayTextToNumber(text: string, format: NumberFormat | string): number {
    switch (format) {
        case 'duration':
            return durationFromText(text)
        case 'filesize':
            return fileSizeFromText(text)
        case 'time':
            return timeFromText(text)
        default:
            return Number(text)
    }
}

export function numberToDisplayText(
    number: number,
    format: NumberFormat | string,
    length: TextLength = 'display'
): string {
    switch (format) {
        case 'duration':
            return durationToText(number, length)
        case 'filesize':
            return fileSizeToText(number, length)
        case 'time':
            return timeToText(number, length)
        default:
            return `${number}${format}`
    }
}

export function roundWithSuffix(number = 0) {
    let suffix
    let roundedNumber
    if (number >= 1e12) {
        suffix = 'T'
        roundedNumber = number / 1e12
    } else if (number >= 1e9) {
        suffix = 'B'
        roundedNumber = number / 1e9
    } else if (number >= 1e6) {
        suffix = 'M'
        roundedNumber = number / 1e6
    } else if (number >= 1e3) {
        suffix = 'K'
        roundedNumber = number / 1e3
    } else {
        suffix = ''
        roundedNumber = number
    }
    return parseFloat(roundedNumber.toFixed(1)) + suffix
}
